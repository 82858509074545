@import "assets/css/default/app.min.css";

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100dvh;
  box-sizing: content-box;
}

label {
  margin-bottom: 0px;
}

.App {
  background-color: #282c34;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  -webkit-box-pack: center;
  overflow: auto;
}

.App-content {
  background-color: #ffffff;
  height: 100dvh;
  min-width: 360px;
  max-width: 360px;
  display: flex;
  flex-direction: column;
}

.App-download {
  background-color: #FFE7E8;
  height: 100dvh;
  min-width: 600px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  display: block;
  position: relative;
}


.logo-div {
  position: absolute;
  top: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.download-div {
  position: absolute;
  bottom: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin-left: 0px;
}

.login-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-success.btn-success-v2 {
  background-color: #ff545a;
  border: #ff545a;
}

.dashboard-div {
  background-color: #fff;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.header-div {
  width: 100%;
  height: 56px;
  flex-direction: row;
  /* background-image: url(../public/assets/ic_main_title.png);
  background-repeat: no-repeat;
  background-size: 90px 24px;
  background-position: left 10px center; */
  display: flex;
  align-items: center;
  justify-content: right;
}

.header-point-div {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 10px;
}

.header-point-label {
  color: #ff545a;
  margin-left: 4px;
}

.tab-div {
  width: 100%;
  height: 60px;
  background-color: rgba(255, 209, 209, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}

.tab-item-div {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tab-label {
  font-size: 12px;
  color: #b5b5b5;
}

.tab-label.selected {
  color: #FE8185;
}

.img-contain {
  object-fit: contain;
}

.img-dot-contain {
  margin-left: 4px;
  margin-right: 4px;
  object-fit: contain;
}

.img-cover {
  object-fit: cover;
}

.container-div {
  width: 100%;
  height: calc(100% - 116px);
}

.fragment-div {
  width: 100%;
  height: 100%;
}

.fragment-div.horizontal {
  display: flex;
  flex-direction: row;
}

.myinfo-div {
  width: 100%;
  height: 100px;
  background-color: rgba(255, 232, 232, 0.8);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.profile-img-div {
  position: relative;
}

.img-badge {
  position: absolute;
  bottom: 0;
  right: 0;
}

.profile-nick-div {
  flex-grow: 100;
  margin-left: 10px;
  margin-right: 10px;
}

.profile-nick-label {
  color: #474747;
  font-size: 13px;
}

.profile-age-label {
  color: #828282;
  font-size: 11px;
}

.scroll-menu-div {
  width: 100%;
  height: calc(100% - 100px);
  overflow-y: auto;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 20px;
}

.menu-sub-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.menu-label {
  font-size: 12px;
  color: #9e9e9e;
}

.menu-item-div {
  width: calc(100% - 36px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.live-menu-div {
  width: 100%;
  height: 50px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.chat-menu-item {
  height: 50px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chat-item-div {
  width: 100%;
  height: 30px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-item-div.selected {
  background-color: #FE8185;
}

.chat-label {
  font-size: 12px;
  color: #B5B5B5;
}

.chat-label.selected {
  color: #fff;
}

.rank-guide-div {
  height: 64px;
  margin: 9px 16px 9px 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background: linear-gradient(to bottom, #FF8589, #FF4E54);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.rank-guide-label-div {
  flex-grow: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rank-guide-label-1 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.rank-guide-label-2 {
  font-size: 10px;
  color: #fff;
}

.top-live-div {
  width: 33%;
  background-color: #FFE1E2;
}

.live-div {
  width: 67%;
}

.top-live-header-div {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #FE8185;
}

.top-live-header-label {
  font-size: 13px;
  color: #FF777A;
  margin-right: 20px;
}

.live-sort-div {
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.chat-menu-sort-div {
  flex-grow: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}

.dashboard-div ul {
  list-style-type: none;
  padding-left: 0;
  padding-right: 0;
}

.dashboard-div li {
  list-style-type: none;
  padding-left: 0;
  padding-right: 0;
}

.chat-unread-div {
  width: 20px;
  height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF777A;
  border-radius: 10px;
}

.chat-unread-label {
  color: #fff;
  font-size: 11px;
}

.chat-nick-label {
  color: #474747;
  font-size: 11px;
}

.chat-age-label-man {
  color: #8981FE;
  font-size: 10px;
}

.chat-age-label-girl {
  color: #FF777A;
  font-size: 10px;
}

.chat-before-label {
  color: #A8A8A8;
  font-weight: 400;
  font-size: 10px;
}

.chat-content-label {
  color: #828282;
  font-size: 11px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.scroll-chat-div {
  height: calc(100% - 50px);
  overflow-y: auto;
}

.scroll-rank-div {
  height: calc(100% - 132px);
  overflow-y: auto;
}

.scroll-talk-div {
  height: 100%;
  overflow-y: auto;
  background-color: #FFE8F8;
}

.rank-prev-label {
  font-size: 10px;
  color: #FF8976;
}

.rank-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.rank-flag-div {
  position: relative;
}

.rank-label {
  font-size: 10px;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  text-align: center;
}

.rank-label-1 {
  font-size: 10px;
  color: #FF8976;
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  text-align: center;
}

.rank-nick-label {
  color: #474747;
  font-size: 13px;
}

.rank-like-label {
  font-size: 11px;
  color: #828282;
  margin-left: 2px;
}

.rank-like-div {
  display: flex;
  align-items: center;
  justify-content: left;
}

.rank-btn-label {
  font-size: 10px;
  color: #FFAAAA;
}

.rank-btn-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.talk-btn-label {
  font-size: 10px;
  color: #B5B5B5;
}


.talk-content-div {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: left;
}

.talk-content-label {
  flex-grow: 100;
  color: #828282;
  font-size: 11px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.talk-nick-div {
  flex-grow: 100;
}

.talk-profile-div {
  margin-right: 10px;
}

.talk-div {
  width: 100%;
  height: 100%;
  position: relative;
}

.img-talk-write {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.scroll-live-div {
  height: calc(100% - 50px);
  overflow-y: auto;
}

.img-top {
  position: absolute;
  top: 0;
  right: 0;
}

.top-rank-label {
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  color: #fff;
}

.top-rank-label.green {
  color: #0f0;
}

.top-rank-label.red {
  color: #f00;
}

.live-user-ul li {
  float: left;
  width: 50%;
}

.in-video-div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-info-div {
  width: 100%;
  background-color: #FFEEEE;
  padding: 8px;
}

.live-msg-label {
  color: #828282;
  font-size: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.live-nick-label {
  color: #474747;
  font-size: 10px;
  flex-grow: 100;
}

.live-nick-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.common-header-div {
  width: 100%;
  height: 54px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: left;
}

.common-header-div.borderb {
  border-bottom: 1px solid #eee !important;
}

.common-header-title-label {
  color: #474747;
  font-size: 14px;
}

.common-header-back-div {
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.point-history-header-div {
  width: 100%;
  height: 50px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 15px;
  border-bottom: 1px solid #eee;
}

.point-history-header-point-label {
  flex-grow: 100;
  color: #FD8185;
  font-size: 14px;
}

.scroll-point-history-div {
  height: calc(100% - 104px);
  overflow-y: auto;
  background-color: #fff;
}

.point-history-type-label {
  color: #828282;
  font-size: 12px;
}

.point-history-date-label {
  color: #BEBEBE;
  font-size: 9px;
}

.point-history-charge-label {
  color: #8981FE;
  font-size: 12px;
}

.point-history-charge-label-1 {
  color: #FD8185;
  font-size: 12px;
}

.point-history-item-div {
  flex-grow: 100;
  flex-direction: column;
  display: flex;
}

.point-charge-my-point-label {
  color: #828282;
  font-size: 11px;
}

.point-charge-mypoint-div {
  flex-grow: 100;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.point-charge-history-div {
  padding-left: 6px;
  padding-right: 6px;
  height: 30px;
  background-color: #7E92FE;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 15px;
}

.scroll-blocklist-div {
  height: calc(100% - 54px);
  overflow-y: auto;
  background-color: #fff;
}

.delete-btn-label {
  font-size: 10px;
  color: #B0B0B0;
}

.notice_tab-item-div {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.notice_container-div {
  width: 100%;
  height: calc(100% - 104px);
}

.scroll-notice-div {
  height: 100%;
  overflow-y: auto;
}

.notice-time-label {
  font-size: 9px;
  color: #BEBEBE;
}

.notice-content-label {
  font-size: 12px;
  color: #828282;
}

.notice-content-div {
  width: 100%;
}

.event-status-label {
  font-size: 32px;
  color: #fff;
  font-weight: bold;
}

.empty-div {
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.event-prepare-label {
  font-size: 14px;
  color: #828282;
  margin-top: 10px;
}

.deposit-name-label {
  flex-grow: 100;
  font-size: 14px;
  color: #FF7A7D;
}

.deposit-input-box {
  text-align: end;
  margin-right: 15px;
  width: 50%;
  font-size: 12px;
  color: #A1A1A1;
  background-color: #fff;
  border: 0;
  outline: 0;
}

.deposit-input-box:focus {
  outline: none ! important;
}

.passbook-point-label {
  flex-grow: 100;
  margin-left: 10px;
  font-size: 14px;
  color: #A1A1A1;
}

.passbook-point-label.selected {
  color: #FF545A;
}

.passbook-price-label {
  font-size: 14px;
  color: #A1A1A1;
}

.passbook-price-label.selected {
  color: #FF545A;
}

.passbook-desc-label {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  font-size: 10px;
  color: #A1A1A1;
}

.request-deposit-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btn-request-deposit {
  width: 160px;
  height: 40px;
  border-radius: 20px;
  background-color: #FF7A7D;
  border: 0;
  outline: 0;
  font-size: 14px;
  color: #fff;
  margin-bottom: 30px;
}

.setting-header-label {
  flex-grow: 100;
  color: #FD8185;
  font-size: 12px;
}

.setting-paragraph-label {
  flex-grow: 100;
  color: #828282;
  font-size: 14px;
}

.setting-header-div {
  width: 100%;
  height: 50px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 15px;
}

.setting-paragraph-div {
  width: 100%;
  height: 50px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 15px;
  border-bottom: 1px solid #eee;
}

.setting-appversion-label {
  color: #FD8185;
  font-size: 12px;
  margin-right: 15px;
}

.useguide_paragraph_div {
  height: 50px;
  border: 1px solid #eee;
  border-radius: 6px;
  margin-left: 20px;
  margin-right: 20px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 20px;
  padding-right: 20px;
}

.useguide-paragraph-label {
  flex-grow: 100;
  color: #FD8185;
  font-size: 14px;
}

.useguide-desc-label {
  font-size: 11px;
  color: #A1A1A1;
}

.useguide-desc-highlight-label {
  color: #FF7A7D;
}

.profile-report-div {
  width: 70px;
  height: 22px;
  border-radius: 11px;
  border: 1px solid #FF7A7D;
  color: #FF7A7D;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-block-div {
  width: 70px;
  height: 22px;
  border-radius: 11px;
  border: 1px solid #7A7AFF;
  color: #7A7AFF;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashed-line-div {
  height: 1px;
  border-bottom: 1px dashed #EBEBEB;
}

.profile-score-div {
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.profile-score-sub-div {
  flex-grow: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.point-price-div {
  width: 80px;
  height: 24px;
  border-radius: 12px;
  background-color: #fff;
  color: #FD8185;
  font-size: 11px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-header-div {
  height: 190px;
  background-color: #FFEFEF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-photo-desc-label {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  color: #828282;
}

.profile-paragraph-label {
  font-size: 12px;
  color: #FE8185;
}

.profile-gender-label {
  font-size: 13px;
  color: #828282;
}
.profile-gender-label.selected {
  color: #FE8185;
}

.profile-paragraph-div {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.nickname-input-box {
  text-align: start;
  width: 100%;
  font-size: 12px;
  background-color: #fff;
  border: 0;
  outline: 0;
}

.nickname-input-box:focus {
  outline: none ! important;
}

.profile-age-label {
  flex-grow: 100;
  font-size: 12px;
  color: #A1A1A1;
}

.depositor-input-box {
  text-align: end;
  flex-grow: 100;
  font-size: 12px;
  color: #A1A1A1;
  background-color: #fff;
  border: 0;
  outline: 0;
}

.depositor-input-box:focus {
  outline: none ! important;
}

.deposit-paragraph-div {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 0 10px 0 15px;
}

.req-paragraph-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #FF7A7D;
  margin: 15px 15px 10px 15px;
}

.no-refund-history-label {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #FF7A7D;
}

.chat-desc-label {
  width: 100%;
  height: 40px;
  text-align: center;
  font-size: 10px;
  color: #474747;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-menu-div {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chat-menu-item-div {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chat-msg-div {
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chat-msg-item-div {
  width: 40px;
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chat-msg-input-div {
  flex-grow: 100;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #DFDFDF;
  padding: 0 14px;
}

.msg-input-box {
  font-size: 12px;
  color: #A1A1A1;
  background-color: #fff;
  border: 0;
  outline: 0;
  width: 100%;
  margin-top: 10px;
  padding: 0;
}

.report-type-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 5px;
}

.modal-dialog {
  max-width: 960px;
  margin: auto;
}

.modal-content {
  max-width: 360px;
  margin-left: auto;
  background: transparent;
  padding: 20px;
  border: none;
}

@media(max-width: 1030px) {
  .App-download {
      display: none;
  }

  .modal-content {
    margin: auto;
  }
}

@media(max-width: 767px) {

  .App-content {
    min-width: 360px;
    max-width: 100vw;
    width: 100vw;
  }

  .modal-dialog {
    max-width: 100vw;
  }
  
  .modal-content {
    max-width: 100vw;
  }

  .live-sort-div {
    width: 150px;
  }
}